import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DatePicker } from '@mui/x-date-pickers';
import { useLocales } from 'src/locales';
import moment from 'moment';
import isValid from 'date-fns/isValid';
// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  controlObj: any
  name: string;
  prefixText?: string;
  conditionFlags: any;
  currentData: any;
  onChangeHandler?:any;
};

export default function DAS360DatePicker({onChangeHandler, currentData, conditionFlags, controlObj, name, helperText, type, prefixText, ...other }: Props) {
  const { t } = useLocales();
  const { control, setError, register,setValue,getValues } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  const datePickerProps:any = {};

  if(name==='DateofVisit.VISDAT') {
     datePickerProps.minDate=new Date();
     if(!getValues(name)){
      setValue(name,new Date())
     }
     console.log(name)
  }

  if (!currentData) {
    currentData = { logData: [] } as any;
  } else if (!currentData.logData) {
    currentData.logData = [];
  }

// date = date.slice(1,11)
console.log(moment(getValues(name)).format("DD-MMM-yyyy"))
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required.`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }
 
  const setLogData = useCallback((field: any, event: any) => {
    if (moment(event).format("DD-MMM-yyyy") !== moment(currentData[field.name]).format("DD-MMM-yyyy")) {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== name)
      let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
      oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
      if(onChangeHandler) {
        const onchngeDaa ={oldValue:moment(oldValue).format("DD-MMM-yyyy"),newValue:moment(event).format("DD-MMM-yyyy")}
        onChangeHandler(onchngeDaa);
      }
      currentData.logData.push({ pageId: viewId, fieldName: field.name, label: controlObj.label, oldValue:moment(oldValue).format("DD-MMM-yyyy") , newValue:moment(event).format("DD-MMM-yyyy")  })
    } else {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== name)
    }
  }, [])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? { justifyContent: 'flex-start',backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
  const bgBorderColor:any = {};
  return (
    <Controller {...register }
    name={name}
    control={control}
    render={({ field, fieldState: { error } }) => (
      <> {!controlObj.showOnlyInGrid &&  <DatePicker {...datePickerProps} sx={{...bgBorderColor}}  {...register }  {...field}
        disabled={controlObj.isReadonly || !conditionFlags?.enable}
            value={field.value?new Date(field.value) : null}
            onChange={(newValue) => {
              console.log(newValue)
               setValue(name,newValue)
               // field.onChange(newValue);
                setLogData(field,newValue); 
            }}
            format="dd-MMM-yyyy"
            slotProps={{
                textField: { 
                    size: 'small',
                    error: !!error,
                    helperText: error?.message,
                    fullWidth:true,
                    label: controlObj.label,placeholder:'Date Control'
                },
            }}
        />}</>
    )}
/>
    // <Controller
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => (
    //     <DatePicker  
    //       {...field}
    //       fullWidth
    //       // InputProps={{
    //       //   startAdornment: <InputAdornment position="start">{prefixText}</InputAdornment>,
    //       // }}
    //       value={field.value}
    //       onChange={(event:any) => {
    //         field.onChange(event);
    //       }}
    //       error={!!error}
    //       format={controlObj.format || "dd-MMM-yyyy"}
    //       slotProps={{
    //         textField: {
    //           size: 'small',
    //           error: !!error,
    //           helperText: error?.message,
    //         },
    //       }}
    //       {...other}
    //     />
    //   )}
    // />
  );
}
