import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  prefixText?: string;
  controlObj: any;
  conditionFlags: any;
  currentData: any;
  onChangeHandler?: any
};

export default function DAS360AlphaCharaterTextField({ onChangeHandler, currentData, conditionFlags, controlObj, name, helperText, type, prefixText, ...other }: Props) {
  const { control, register, setValue,getValues } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  if (!currentData) {
    currentData = { logData: [] } as any;
  } else if (!currentData.logData) {
    currentData.logData = [];
  }
  // let maxLength: any = {};
  // let minLength: any = {};
  // if (controlObj.min) {
  //   minLength = {
  //     value: controlObj.min,
  //     message: controlObj.minMaxErrorMessage || `${controlObj.label} must have max length.`,
  //   }
  // }
  // if (controlObj.max) {
  //   maxLength = {
  //     value: controlObj.max,
  //     message: controlObj.minMaxErrorMessage || `${controlObj.label} must have max length`,
  //   }
  // }

  const setLogData = useCallback((field: any, event: any) => {
    if (event.target.value !== currentData[field.name]) {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field.name)
      if (onChangeHandler) {
        const onchngeDaa = { oldValue: currentData[field.name], newValue: event.target.value }
        onChangeHandler(onchngeDaa);
      }
      let oldValue = JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".", '')
      oldValue = currentData[oldValue] ? currentData[oldValue][controlObj.columnName] : currentData[field.name]
      currentData.logData.push({ pageId: viewId, fieldName: field.name, label: controlObj.label, oldValue, newValue: event.target.value })
    } else {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field.name)
    }
  }, [])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
  const bgBorderColor: any = {};
  const checkSpecialChar = (e: any) => {
    if (!/^[a-zA-Z/-]+$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (controlObj.conditionalLogic && conditionFlags?.show === "flex" && controlObj.conditionalLogic === getValues(`${controlObj.subFormName}${controlObj.dependencyField}`)) {
          register(name, {
            ...register,
            required: controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.`,
          })
        } else if (controlObj.conditionalLogic) {
          register(name, { ...register, required: { value: false, message: "" } })
        } else {
          register(name, { ...register,  required: { value: controlObj.required, message: controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.` } })
        }
        return <> {!controlObj.showOnlyInGrid && <TextField sx={{ ...bgBorderColor }}  {...register}
          onKeyUp={(e: any) => {
            checkSpecialChar(e); setValue(field.name, e.target.value?.replace(/[^a-zA-Z-]/g, ""))
          }} onKeyDown={(e: any) => {
            checkSpecialChar(e); setValue(field.name, e.target.value?.replace(/[^a-zA-Z-]/g, ""))
          }
          }
          disabled={controlObj.isReadonly || !conditionFlags?.enable}
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event: any) => {

            if (controlObj.max && event.target.value.length <= controlObj.max) {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
              setLogData(field, event);
            }
            else if (!controlObj.max) {
              if (type === 'number') {
                field.onChange(Number(event.target.value));
              } else {
                field.onChange(event.target.value);
              }
              setLogData(field, event);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />}</>
      }}
    />
  );
}
