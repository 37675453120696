import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { Chip } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  controlObj: any;
  conditionFlags: any;
  currentData: any;
  onChangeHandler?:any;
}

export default function DAS360MultiAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({ currentData, conditionFlags, controlObj,
  name,
  label,
  placeholder,
  helperText,
  onChangeHandler,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue, register, getValues } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  const { t } = useLocales();
  const optionData: any =  controlObj?.options?.split(',') || [];
  let defaultControlValuesList: any = [];
  defaultControlValuesList = optionData?.filter((x: any) => getValues(name)?.split(',')?.includes(x))
 
  if (!currentData) {
    currentData = { logData: [] } as any;
  } else if (!currentData.logData) {
    currentData.logData = [];
  }
  const setLogData = useCallback((field: any, event: any) => {
    if (event !== currentData[field]) {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field)
      const filterData = optionData?.filter((x: any) => currentData[field]?.split(',').includes(x.toString()))
      let cancatinate: any = "";
      filterData?.forEach((element: any) => {
        if (filterData.length === 1) {
          cancatinate += `${element}`;
        } else {
          cancatinate += `${element},`;
        }
      });
      if(onChangeHandler) {
        const onchngeDaa ={oldValue:cancatinate,newValue:event}
        onChangeHandler(onchngeDaa);
      }
      currentData.logData.push({ pageId: viewId, fieldName: field, label: controlObj.label, oldValue: cancatinate, newValue: event })
    } else {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field)
    }
  }, [])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
  const bgBorderColor:any = {};

  const condition =()=>{
    if (controlObj.conditionalLogic && conditionFlags?.show === "flex"  && controlObj.conditionalLogic === getValues(`${controlObj.subFormName}${controlObj.dependencyField}`))  {
      conditionFlags.enable = true;
      register(name, {...register,
        required: controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.`,
      })
    } else if(controlObj.conditionalLogic) {
      conditionFlags.enable = false;
      register(name, {...register,required:{value:false,message:""}})
    } else {
      conditionFlags.enable = true;
      register(name, {...register,required:{value:controlObj.required,message:controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.`}})
    }
  }
       return <> {condition()} {!controlObj.showOnlyInGrid &&  <Autocomplete    {...register} readOnly={controlObj.isReadonly || !conditionFlags?.enable}
       fullWidth
       multiple
       options={optionData}
       getOptionLabel={(option:any) => option}
       sx={{...bgBorderColor}} disabled={controlObj.isReadonly || !conditionFlags?.enable}  key={`${crypto.randomUUID()}`}  size={GLOBALCLASSINPUT}
       defaultValue={defaultControlValuesList}
       onChange={(event: any, newValue: any) => {
         let ids = "";
         newValue?.forEach((item: any) => {
           ids += `${item},`;
         });
         // setValue("controlIds", ids);
         const filterData = optionData?.filter((x: any) => ids?.split(',')?.includes(x.toString()))
         let cancatinate: any = "";
         filterData.forEach((element: any) => {
           if (filterData.length === 1) {
             cancatinate += `${element}`;
           } else {
             cancatinate += `${element},`;
           }
         });
         setValue(`${name}1`, cancatinate);
         setLogData(name,cancatinate);
         setValue(`${name}`, ids);
       }}
       renderInput={(params) => (
         <TextField {...params} label={controlObj.label} placeholder={controlObj.label} />
       )}
       renderOption={(props, option) => (
         <li {...props} key={option}>
           {option}
         </li>
       )}
       renderTags={(selected, getTagProps) =>
         selected.map((option, mindex) => (
           <Chip
             {...getTagProps({ index:mindex })}
             key={option}
             label={option}
             size="small"
             variant="soft"
           />
         ))
       }
     />}</>
}