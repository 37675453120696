import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IFormBlockSetupState } from 'src/types/settings/form-block-setup';
// utils
import axios, { API_ENDPOINTS } from 'src/utils/axios';

const initialState: IFormBlockSetupState = {
    formBlockSetups:[],
    formBlockSetupStatus:{
        loading:false,
        empty:false,
        error:null
    },
    formBlockSetupDelete: {
        loading:false,
        empty:false,
        error:null
    }
}

const slice = createSlice({
    name: 'formBlockSetup',
    initialState,
    reducers:{
        getFormBlockSetupStart(state) {
            state.formBlockSetupStatus.loading=true;
            state.formBlockSetupStatus.empty=false;
            state.formBlockSetupStatus.error = null;
        },
        getFormBlockSetupFailure(state, action) {
            state.formBlockSetupStatus.loading=true;
            state.formBlockSetupStatus.empty=false;
            state.formBlockSetupStatus.error = action.payload;
        },
        getFormBlockSetupSuccess(state, action) {
            const formBlockSetup = action.payload;
            state.formBlockSetups = formBlockSetup;
            state.formBlockSetupStatus.loading = false;
            state.formBlockSetupStatus.empty = !formBlockSetup.length;
            state.formBlockSetupStatus.error = null;
        },
        deleteformBlockSetupStart(state) {
            state.formBlockSetupDelete.loading=true;
            state.formBlockSetupDelete.empty=false;
            state.formBlockSetupDelete.error = null;
        },
        deleteformBlockSetupFailure(state, action) {
            state.formBlockSetupDelete.loading=true;
            state.formBlockSetupDelete.empty=false;
            state.formBlockSetupDelete.error = action.payload;
        },
        deleteformBlockSetupSuccess(state, action) {
            const formBlockSetupDelete = action.payload;
            state.formBlockSetupDelete = formBlockSetupDelete;
            state.formBlockSetupDelete.loading = false;
            state.formBlockSetupDelete.empty = action.payload;
            state.formBlockSetupDelete.error = null;
        }
    }
});

export default slice.reducer;

// Actions
export const {
    getFormBlockSetupStart,
    getFormBlockSetupFailure,
    getFormBlockSetupSuccess,
    deleteformBlockSetupStart,
    deleteformBlockSetupFailure,
    deleteformBlockSetupSuccess
} = slice.actions;
 

export function getFormBlockSetups(data:any) {
    return async (dispatch: Dispatch) => {
        dispatch(slice.actions.getFormBlockSetupStart());
        try {
            await axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.LIST}/${data}`).then((res:any)=> {
                if(res.data.statusCode === 200)
                dispatch(slice.actions.getFormBlockSetupSuccess(res.data.data));
            else 
                 dispatch(slice.actions.getFormBlockSetupFailure([]));
            });
        }catch(error){
            dispatch(slice.actions.getFormBlockSetupFailure(error));
        }
    }
}

export function deleteFormBlockSetup(id: any,pageId:any) {
    return  axios.delete(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.DELETE}/${id}/${pageId}`);
}


export function deleteFormDataDetail(id: any) {
    return  axios.delete(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.DELETE}/${id}`);
}

export function getFormBlockSetupTableId(id: any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETFORMBLOCKSETUPBYTABLEID}/${id}`);
}

export function getDataCaptureForm(id: any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETDATACAPUTREFORM}/${id}`);
}

export function globalForms(id: any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETFORMBLOCKGLOBALBYID}/${id}`);
}

export function getFormDetailbyFormBlockByPageId(id: any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETFORMDETAILBYFORMBLOCKBYPAGEID}/${id}`);
}

export function getFormBlockControlIds(id: any,pageId:any) {
    return  axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETFORMBLOCKCONTROLIDS}/${id}/${pageId}`);
}

export function insertUpdateForm(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.ADDUPDATEFORMDETAIL,data);
}

export function addUpdateFormBlockSetup(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.ADDUPDATEFORMBLOCKSETUP,data);
}

export function generateScript(data: any) {
    return axios.post(API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GENERATESCRIPT,data);
}

export function UpdateEnrollmentSubjectSubmitStatus(id:any,status:any) {
    return axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.UPDATEENROLLEMENTSUBJECTSTATUS}/${id}/${status}`);
}

export function getDasboard() {
    return axios.get(`${API_ENDPOINTS.SETTINGS.FORMBLOCKSETUP.GETDASBOARD}`);
}