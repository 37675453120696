import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import calendarReducer from './slices/calendar';
import languageReducer from './slices/localization/language';
import localeStringResourceReducer from './slices/localization/locale-string-resource';
import columnDetailReducer from './slices/settings/table-configuration';
import formSetupReducer from './slices/settings/form-setup';
import formBlockSetupReducer from './slices/settings/form-block-setup';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['checkout'],
};

export const rootReducer = combineReducers({
  localeStringResource: localeStringResourceReducer,
  language: languageReducer,
  columnDetail: columnDetailReducer,
  formSetup: formSetupReducer,
  formBlockSetup:formBlockSetupReducer,
  calendar: calendarReducer,
});
