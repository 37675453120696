import { useCallback, useEffect, useMemo, useState } from 'react';
// redux
import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// @mui
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
// locales
// components
import { ISCLIENT, SYSTEM_ADMIN, SYSTEM_ADMIN_CLIENTVIEW } from 'src/auth/context/jwt/auth-provider';
import { useAuthContext } from 'src/auth/hooks';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFAutocomplete, RHFSelect } from 'src/components/hook-form';
import { FormValuesProps } from 'src/sections/_common-components/booking-detail';
import { Autocomplete, Avatar, Divider, TextField } from '@mui/material';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { setSession } from 'src/auth/context/jwt/utils';

// ----------------------------------------------------------------------

export default function ClientListPopover() {
  const { user, onClientSelected, initialize } = useAuthContext();
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>({ ID: 0 });
  const popover = usePopover();
  const navigate = useNavigate();

  useEffect(() => {
    setClientList([...(JSON.parse(user?.clientList) || [])])
    if (sessionStorage.getItem(SYSTEM_ADMIN) === "clientView") {
      const clientDetail: any = JSON.parse(sessionStorage.getItem("C") || '{}');
      setSelectedClient({ ...clientDetail });
      if (clientDetail?.ID) {
        sessionStorage.setItem(ISCLIENT, 'true');
        // if (initialize) initialize();
      }
    }
  }, []);

  const handleChangeClient = useCallback((option: any) => {
    if (option.ID > 0) {
      axios.get(`${API_ENDPOINTS.auth.AdminByClientById}/${option.ID}`).then((res) => {
        const data = res?.data || {};
        if (res?.data?.user) {
          const { accessToken } = data?.user || {};
          setSession(accessToken);
          sessionStorage.setItem("C", JSON.stringify(option))
          setSelectedClient({ ...option });
          sessionStorage.setItem(SYSTEM_ADMIN, "clientView");
          sessionStorage.setItem(ISCLIENT, 'true');
          // if (onClientSelected) onClientSelected(data);
          window.location.href = `/sclinedc/${option?.CompanyName?.trim()?.replace(/ /g, '')?.toLowerCase()}`;
        }
        // navigate(`/${user?.role?.replace(/ /g, '-')?.toLowerCase()}`, { replace: true });
      });
    } else {
      axios.get(`${API_ENDPOINTS.auth.refreshToken}`).then((res) => {
        const data = res?.data?.data || {};
        if (res?.data?.data) {
          const { accessToken } = data?.user || {};
          setSession(accessToken);

          setSelectedClient({ ID: 0 })
          sessionStorage.setItem(SYSTEM_ADMIN, "admin");
          sessionStorage.setItem(ISCLIENT, 'false');
          sessionStorage.removeItem("C");
          // if (initialize) initialize();
          // window.location.href = `/${user?.role?.trim()?.replace(/ /g, '-')?.toLowerCase()}`;
          window.location.href = `/sclinedc`;
        }
      });
    }
  }, [onClientSelected, initialize]);

  const [newModelSchema, setNewModelSchema] = useState({} as any);

  const methods = useForm({
  });

  const {
    reset,
    handleSubmit, setValue, getValues, watch, control,
    formState: { isSubmitting }
  } = methods;
  const onSubmit = useCallback(
    async (data: FormValuesProps) => {
    }, [])

  return (
    <>
      <FormProvider methods={methods}>
        {clientList && clientList.length > 0 && <RHFSelect size={GLOBALCLASSINPUT} name='ClientId' value={selectedClient?.ID} style={{ minWidth: "250px" }} >
          <MenuItem value='0' onClick={() => {
            setValue("ClientId", 0)
            setSelectedClient({ ID: 0 })
            handleChangeClient({ ID: 0 });
          }} >Select Client</MenuItem>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {clientList?.map((item: any) => (
            <MenuItem style={{ minWidth: "250px" }} key={item.ID} value={item.ID} onClick={() => {
              setValue("ClientId", item?.ID)
              setSelectedClient({ ...item })
              handleChangeClient(item);
            }}>
              {item?.Logo ? <Avatar
                src={item?.Logo}
                variant="rounded"
                sx={{ width: 30, height: 30, mr: 2, display: 'inline-block', float: 'left' }}
              /> : <Iconify icon="material-symbols:image" sx={{ width: 30, height: 30, mr: 2 }} />}

              <span style={{ display: 'inline-block', float: 'left', marginTop: '5px' }}> {item?.CompanyName} </span>
            </MenuItem>
          ))}
        </RHFSelect>}
      </FormProvider>
      {/* {selectedClient?.CompanyName} */}

      {/* {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>} */}
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }} arrow="left-top">
        {clientList.map((option: any) => (
          <MenuItem
            key={option.ID}
            selected={option.ID === selectedClient?.ID}
            onClick={() => handleChangeClient(option)}>
            {option?.Logo ? <Avatar
              src={option?.Logo}
              variant="rounded"
              sx={{ width: 54, height: 54, mr: 2, float: 'left' }}
            /> : <Iconify icon="material-symbols:image" sx={{ width: 54, height: 54, mr: 2 }} />}
            {option?.CompanyName}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
