import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DesktopTimePicker } from '@mui/x-date-pickers';
// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  prefixText?: string;
  controlObj:any;
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any,
  subFormName:any;
};

export default function DAS360TimePicker({subFormName,onChangeHandler,currentData,conditionFlags,controlObj, name, helperText, type, prefixText, ...other }: Props) {
  const { control,register ,getValues,setValue } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }

    const setLogData = useCallback((field:any,event:any)=>{
      if(event.target.value!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
        oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
        if(onChangeHandler) {
          const onchngeDaa ={oldValue,newValue:event.target.value}
          onChangeHandler(onchngeDaa);
        }

        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue,newValue:event.target.value})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {borderRadius:"10px",border:`1px solid`}:{};
  const bgBorderColor:any = {};
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <> {!controlObj.showOnlyInGrid &&  <DesktopTimePicker {...register} disabled={controlObj.isReadonly || !conditionFlags?.enable}
          label={controlObj.label}
          value={getValues(`${subFormName}${controlObj.dependencyField}`)}
          onChange={(newValue: any) => {
              console.log(newValue)
              setValue(`${subFormName}${controlObj.dependencyField}`, newValue);
          }}
          slotProps={{
              textField: {
                  fullWidth: true,
                  margin: 'normal',
                  ...other   ,
                  sx:{...bgBorderColor}     
                },
          }}
        
      />}</>
      )}
    />
  );
}
