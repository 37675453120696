// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
// theme
import { bgGradient } from 'src/theme/css';
import { useAuthContext } from 'src/auth/hooks';
import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  title?: string;
  description?: string;
  img?: React.ReactNode;
  action?: React.ReactNode;
}

export default function AppWelcome({ title, description, action, img, ...other }: Props) {
  const theme = useTheme();
  const { user } = useAuthContext();
  const [clientData,setClientData] = useState<any>({})
   useEffect(()=>{
    const client = sessionStorage.getItem("C")||'{}';
    setClientData(JSON.parse(client));
  },[sessionStorage.getItem("C")])
  return (
    <Stack
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette.primary.light, 0.2),
          endColor: alpha(theme.palette.primary.main, 0.2),
        }),
        height: { md: 1 },
        borderRadius: 2,
        position: 'relative',
        color: 'primary.darker',
        backgroundColor: 'common.white'
      }}
      {...other}
    >
      <Stack
        flexGrow={1}
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        sx={{
          p: {
            xs: theme.spacing(5, 3, 0, 3),
            md: theme.spacing(5),
          },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Typography paragraph variant="h4" sx={{ whiteSpace: 'pre-line' }}>
        {clientData?.Logo ? <Avatar
              src={clientData?.Logo}
              variant="rounded"
              sx={{ width: 50, height: 50, mr: 2,display:'inline-block',float:'left'  }}
            /> : ''}
             <span style={{display:'inline-block',float:'left',marginTop:'8px'  }}>     {clientData?.CompanyName?`Welcome to ${clientData?.CompanyName}`:title} </span>
          
        </Typography>

        <Typography
          variant="body2"
          sx={{
            opacity: 0.8,
            maxWidth: 360,
            mb: { xs: 3, xl: 5 },
          }}
        >
           {/* <Typography paragraph variant="h2" sx={{ whiteSpace: 'pre-line' }}>
         Coming soon!
        </Typography> */}
         <b>{description}</b> 
        </Typography>

        {/* {action && action} */}
      </Stack>

      {img && (
        <Stack
          component="span"
          justifyContent="center"
          sx={{
            p: { xs: 5, md: 3 },
            maxWidth: 360,
            mx: 'auto',
            background:`url(${clientData?.Logo?clientData?.Logo:''})`
          }}
        >
          {clientData?.Logo?'':img}
        </Stack>
      )}
    </Stack>
  );
}
