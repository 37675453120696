import { useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import { useAuthContext } from 'src/auth/hooks';
//
import Main from './main';
import Header from './header';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import NavHorizontal from './nav-horizontal';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings:any = useSettingsContext();
  const { user } = useAuthContext();
  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini =useCallback(()=>  <NavMini /> ,[user]);

  const renderHorizontal = useCallback(()=> <NavHorizontal /> ,[user]);

  const renderNavVertical = useCallback(()=> <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} /> ,[user]);

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {lgUp ? renderNavMini() : renderNavVertical()}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  const header = <Header onOpenNav={nav.onTrue} />

  return (
    <>
     {header}

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical()}

        <Main>{children}</Main>
      </Box>
    </>
  );
}
