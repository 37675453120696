import { alpha } from '@mui/material/styles';
import { Divider, Grid, Typography } from "@mui/material";
import { borderRadius, Stack } from "@mui/system";
import { Fragment, useCallback, useEffect } from "react";
import DynamicFormTemplate from "../dynamic-form-template";

type Props = {
    index: any;
    item: any;
    currentModel: any;
    setValue: any;
    getValues: any;
    control: any;
    setNewModelSchema: any;
    inputTypeSize: any;
    onAttachmentClick?: any;
    onNotesClick?: any;
    onSubmitQueryClick?: any;
    onChangeHandler?:any;
};

export default function DefaultDynamicForm({ index, item, currentModel, setNewModelSchema, setValue, getValues, control, inputTypeSize,
    onAttachmentClick, onNotesClick, onSubmitQueryClick,onChangeHandler }: Props) {
    const randomUUID: any = crypto.randomUUID();
    const itemData: any = JSON.parse(JSON.stringify(item));
    let data: any = JSON.parse(JSON.stringify(item));
    const isFileUpload = itemData?.formDetail?.filter((x: any) => x.inputType === 'PICTUREUPLOAD');
    if(isFileUpload.length === 1)
    data = data?.formDetail?.filter((x: any) => x.inputType !== 'PICTUREUPLOAD');

   const renderForm = useCallback(()=> {

    },[])
    const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {border:`1px solid`,padding:"5px 15px 15px 15px",borderColor: (theme:any) => alpha('#97c8e5', 0.6),borderRadius:'10px'}:{};
    
    return <Fragment key={`${randomUUID}1`}>
        {item.blockName !== "Default" && <Fragment key={`${randomUUID}2`} >
            <Typography variant="subtitle1" key={`${randomUUID}2`} style={{ width: "100%", paddingTop: "10px" }}> {item.blockName} </Typography>
            <Divider key={`${randomUUID}3`} sx={{ borderStyle: 'dashed', marginBottom: "10px" }} />
        </Fragment>}
        <Fragment key={`${randomUUID}4`}>
            {isFileUpload.length===1  ? (<Grid key={`${crypto.randomUUID()}`} container >
            <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={8}>
                    <Stack spacing={2} display="grid" key={`${randomUUID}5`} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
                        <Fragment key={`${randomUUID}6`}>
                            <DynamicFormTemplate onChangeHandler={onChangeHandler} onAttachmentClick={onAttachmentClick} onNotesClick={onNotesClick}
                                onSubmitQueryClick={onSubmitQueryClick} key={`${crypto.randomUUID()}`} inputTypeSize={inputTypeSize} subFormName={item.subFormName ? `${item.subFormName}.` : ""} formDetails={data} currentData={currentModel}
                                indexValue={index} setValue={setValue} getValues={getValues} control={control} />
                        </Fragment>
                    </Stack>
                </Grid>
                <Grid key={`${crypto.randomUUID()}`} item xs={12} lg={4}>
                    <Stack spacing={2} display="grid" key={`${randomUUID}5`} gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(1, 1fr)` }}>
                        <Fragment key={`${randomUUID}6`}>
                            <DynamicFormTemplate onChangeHandler={onChangeHandler} onAttachmentClick={onAttachmentClick} onNotesClick={onNotesClick}
                                onSubmitQueryClick={onSubmitQueryClick} key={`${crypto.randomUUID()}`} inputTypeSize={inputTypeSize} subFormName={isFileUpload.subFormName ? `${item.subFormName}.` : ""} formDetails={isFileUpload} currentData={currentModel}
                                indexValue={index} setValue={setValue} getValues={getValues} control={control} />
                        </Fragment>
                    </Stack>
                </Grid>
                
            </Grid>) : (<Stack spacing={2} display="grid" key={`${randomUUID}5`}  gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: `repeat(${item.flex}, 1fr)` }}>
                <Fragment key={`${randomUUID}6`}>
                    <DynamicFormTemplate onChangeHandler={onChangeHandler} onAttachmentClick={onAttachmentClick} onNotesClick={onNotesClick}
                        onSubmitQueryClick={onSubmitQueryClick} key={`${crypto.randomUUID()}`} inputTypeSize={inputTypeSize} subFormName={item.subFormName ? `${item.subFormName}.` : ""} formDetails={item.formDetail} currentData={currentModel}
                        indexValue={index} setValue={setValue} getValues={getValues} control={control} />
                </Fragment>
            </Stack>)
            }
        </Fragment>
    </Fragment>
}