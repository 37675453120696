import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  prefixText?: string;
  controlObj:any;
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any
};

export default function DAS360TextField({onChangeHandler,currentData,conditionFlags,controlObj, name, helperText, type, prefixText, ...other }: Props) {
  const { control,register,getValues  } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  const inputProp:any = {};
  
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  if(controlObj.min){
    inputProp.minLength=controlObj.min
  }
  if(controlObj.max){
    inputProp.maxLength=controlObj.max
  }
  
    const setLogData = useCallback((field:any,event:any)=>{
      if(event.target.value!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        if(onChangeHandler) {
          const onchngeDaa ={oldValue:currentData[field.name],newValue:event.target.value}
          onChangeHandler(onchngeDaa);
        }
        let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
        oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue,newValue:event.target.value})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
  const bgBorderColor:any = {};
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        if (controlObj.conditionalLogic && conditionFlags?.show === "flex"  && controlObj.conditionalLogic === getValues(`${controlObj.subFormName}${controlObj.dependencyField}`))  {
          register(name, {...register,
            required: controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.`,
          })
        } else if(controlObj.conditionalLogic) {
          register(name, {...register,required:{value:false,message:""}})
        } else {
          register(name, {...register,required:{value:controlObj.required,message:controlObj.requiredErrorMessage || `${controlObj.label || controlObj.columnName} is required.`}})
        }

       return <> {!controlObj.showOnlyInGrid &&  <TextField  sx={{...bgBorderColor}} {...register}
        disabled={controlObj.isReadonly || !conditionFlags?.enable}
        inputProps={{...inputProp }}
        {...field}
          fullWidth
          type={type}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">{prefixText}</InputAdornment>,
          // }}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
            } else {
              field.onChange(event.target.value);
            }
            setLogData(field,event);
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />}</>
      } 
      }
    />
  );
}
