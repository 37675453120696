import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import { useLocales } from 'src/locales';
import { GLOBALCLASSINPUT } from 'src/utils/contants/config-common';
import { Chip } from '@mui/material';

// ----------------------------------------------------------------------

interface Props<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends AutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  controlObj: any;
  conditionFlags: any;
  currentData: any;
  onChangeHandler?:any;
}

export default function DAS360MultiSelectAutocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({ currentData, conditionFlags, controlObj,
  name,
  label,
  placeholder,
  helperText,
  onChangeHandler,
  ...other
}: Omit<Props<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'>) {
  const { control, setValue, register, getValues } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  const { t } = useLocales();
  let optionData: any = !controlObj.dependencyDropdownKeyCol ? controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData) : controlObj.dropdownKeyData && JSON.parse(controlObj.dropdownKeyData)?.filter((x: any) => parseInt(x[controlObj.dependencyDropdownKeyCol], 10) === parseInt(getValues()[controlObj.parentColumnName], 10));
  optionData = typeof optionData === 'string' ? [] : optionData
  let defaultControlValuesList: any = [];
  defaultControlValuesList = optionData?.filter((x: any) => getValues()[`${name}`]?.split(',').includes(x.Id.toString()))

  if (!currentData) {
    currentData = { logData: [] } as any;
  } else if (!currentData.logData) {
    currentData.logData = [];
  }

  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required.`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }

  const setLogData = useCallback((field: any, event: any) => {
    if (event !== currentData[field]) {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field)
      const filterData = optionData?.filter((x: any) => currentData[field]?.split(',').includes(x.Id.toString()))
      let cancatinate: any = "";
      filterData?.forEach((element: any) => {
        if (filterData.length === 1) {
          cancatinate += `${element[controlObj.dropdownLabelName]}`;
        } else {
          cancatinate += `${element[controlObj.dropdownLabelName]},`;
        }
      });
      if(onChangeHandler) {
        const onchngeDaa ={oldValue:cancatinate,newValue:event}
        onChangeHandler(onchngeDaa);
      }
      currentData.logData.push({ pageId: viewId, fieldName: field, label: controlObj.label, oldValue: cancatinate, newValue: event })
    } else {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field)
    }
  }, [])
  // const bgBorderColor:any = sessionStorage.getItem("pageTitle")==='Enroll Subject'? {backgroundColor:'#ffffff',borderRadius:"10px",border:`1px solid`,borderColor: (theme:any) => alpha('#97c8e5', 0.6)}:{};
  const bgBorderColor:any = {};
  return (
   <> {!controlObj.showOnlyInGrid &&  <Autocomplete multiple fullWidth sx={{...bgBorderColor}} disabled={controlObj.isReadonly || !conditionFlags?.enable}  key={`${crypto.randomUUID()}`}  size={GLOBALCLASSINPUT}
     {...register}
    options={optionData}
    defaultValue={defaultControlValuesList}
    getOptionLabel={(option: any) => option[controlObj.dropdownLabelName]}
    onChange={(event: any, newValue: any) => {
      let ids = "";
      newValue?.forEach((item: any) => {
        ids += `${item.Id},`;
      });
      // setValue("controlIds", ids);
      const filterData = optionData?.filter((x: any) => ids?.split(',').includes(x.Id.toString()))
      let cancatinate: any = "";
      filterData.forEach((element: any) => {
        if (filterData.length === 1) {
          cancatinate += `${element[controlObj.dropdownLabelName]}`;
        } else {
          cancatinate += `${element[controlObj.dropdownLabelName]},`;
        }
      });
      setValue(`${name}1`, cancatinate);
      setLogData(name,cancatinate);
      setValue(`${name}`, ids);
    }}
    renderInput={(params: any) => (
      <TextField key={`${name}13`}  {...params} label={t(controlObj.label)} placeholder={controlObj.label} />
    )}
    renderOption={(props: any, option: any) => (
      <li key={`${name}14${option[controlObj.dropdownLabelName]}`} {...props} >
        {option[controlObj.dropdownLabelName]}
      </li>
    )}
    renderTags={(selected: any, getTagProps: any) =>
      selected.map((option: any, indexD: any) => (
        <Chip
          {...getTagProps({ index: indexD })}
          key={`1${name}${indexD}`}
          label={option[controlObj.dropdownLabelName]}
          size={GLOBALCLASSINPUT}
          color="info"
          variant="soft"
        />
      ))
    }
  />}</>
  );
}
