import { alpha } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import { useParams } from 'react-router';
import { useCallback } from 'react';

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  helperText?: React.ReactNode;
  controlObj:any
  conditionFlags:any;
  currentData:any;
  onChangeHandler?:any;
}

export default function DAS360Switch({onChangeHandler,currentData,conditionFlags,controlObj, name, helperText, ...other }: Props) {
  const { control,register,setValue  } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }
  
  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required.`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }
    const setLogData = useCallback((field:any,newValue:any)=>{
      if(newValue!==currentData[field.name]) {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
        let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
      oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
     
        if(onChangeHandler) {
          const onchngeDaa ={oldValue,newValue}
          onChangeHandler(onchngeDaa);
        }
        currentData.logData.push({pageId:viewId,fieldName:field.name,label:controlObj.label,oldValue,newValue})
      } else {
        currentData.logData = currentData.logData?.filter((x:any)=> x.fieldName !== field.name)
      }
    },[])

  return (
    <Controller {...register }
       name={name}
      control={control} 
      render={({ field, fieldState: { error } }) => (
      <> {!controlObj.showOnlyInGrid && <div>
          <FormControlLabel control={<Switch  disabled={controlObj.isReadonly || !conditionFlags?.enable} {...field} checked={field.value}  
          onChange={(e)=>{
            setValue(name,e.target.checked)
            setLogData(field,e.target.checked);
          
          }} />} {...other} />
          
          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>}</> 
      )}
    />
  );
}
