import { useLocation, matchPath } from 'react-router-dom';
import { useAuthContext } from 'src/auth/hooks';
import { SYSTEM_ADMIN } from 'src/auth/context/jwt/auth-provider';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveLink(path: string, deep = true): ReturnType {
  const { user } = useAuthContext();
  const { pathname } = useLocation();
  const clientName = user?.clientName?.replace(/ /g, '-')?.toLowerCase();
  let normalActive:any;
  let deepActive:any;
  if(sessionStorage.getItem(SYSTEM_ADMIN) === "study") {
    normalActive = path ? !!matchPath({path:`study/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${user?.studyName?.replace(/ /g, '-')?.toLowerCase()}/${path}` , end: true }, pathname) : false;
   deepActive = path ? !!matchPath({ path:`study/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${user?.studyName?.replace(/ /g, '-')?.toLowerCase()}/${path}`, end: false }, pathname) : false;
  }else if(sessionStorage.getItem(SYSTEM_ADMIN) === "client") {
    normalActive = path ? !!matchPath({path:`client/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${path}` , end: true }, pathname) : false;
   deepActive = path ? !!matchPath({ path:`client/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${path}`, end: false }, pathname) : false;
  }else if(sessionStorage.getItem(SYSTEM_ADMIN) === "clientView") {
    normalActive = path ? !!matchPath({path:`sclinedc/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${path}` , end: true }, pathname) : false;
   deepActive = path ? !!matchPath({ path:`sclinedc/${user?.clientName?.replace(/ /g, '')?.toLowerCase()}/${path}`, end: false }, pathname) : false;
  }
  else {
      normalActive = path ? !!matchPath({path:`sclinedc/${path}` , end: true }, pathname) : false;
      deepActive = path ? !!matchPath({ path:`sclinedc/${path}`, end: false }, pathname) : false;
  }

  return deep ? deepActive : normalActive;
}
