export const GLOBALCLASSINPUT = "small";

export const TAXDETAIL_GRID_COLUMNS = ["Tax Description", "Tax Rate", "Tax Amount", "Action"]
export const ACCESSORIES_GRID_COLUMNS = ["Part Name", "Part Number", "Selling Price", "Discount", "Quantity", "Action"]
export const SCHEMEANDOFFERS_GRID_COLUMNS = ["Scheme Type", "Scheme Category", "Amount", "Valid From", "Valid To", "Action"]

export const ConstantColumnFields = {
    "label": "",
    "columnName": "",
    "dataType": "",
    "inputType": "",
    "placeholder": "",
    "defaultValue": "",
    "required": true,
    "checkboxLabel": "",
    "tableInfoId": 0,
    "childReference": "",
    "tableInfo": null,
    "displayOrder": 0,
    "gridDisplayOrder": 0,
    "status": false,
    "isReadonly": false,
    "showOnlyInGrid": false,
    "showInGrid": false,
    "isUnique": false,
    "dropdownKey": 0,
    "dropdownKeyLabel": 0,
    "dependencyDropdownKey": 0,
    "parentColumnName": null,
    "refDependencyDropdownKeyCol": "",
    "dropdownLabelName": "",
    "dependencyDropdownKeyCol": "",
    "skipControlId": null,
    "dropdownKeyData": null,
    "tableName": "",
    "options": null,
    "alignment": "",
    "format": "",
    "min": null,
    "max": null,
    "minMaxErrorMessage": "",
    "requiredErrorMessage": "",
    "dependencyField": "", 
    "conditionalLogic": "",
    "actionRequired": "Disable",
    "attachment": false,
     "notes": false,
    "query": false,
    "clear": false,
    "instructions": "",
    "instructionsText": "",
    "id": 0,
    "subFormName": ""
}

