export const DATEFORMAT=""
export const FORMNAMES = {
    DATACAPTURENAME:'DataCaputureName',
    GLOBALFORMS:'GlobalForms',
}

export const CONSTANTDEFAULTPAGES = {
    REGISTRATIONPAGE:36,
    LOGIN:36,
    FORGOTPASSWORD:39,
    CLIENTREGISTRATIONPAGE:111,
    CLINETFORGOTPASSWORD:39,
    STUDYFORGOTPASSWORD:39,
    ATTACHMENT:10128,
    NOTES:10134,
    PUBLISH:2060,
    SUBMITQUERY:1069,
    STUDYTEMPLATE:1059,
    STEPPERFORM:7128
}