import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel, {
  FormControlLabelProps,
  formControlLabelClasses,
} from '@mui/material/FormControlLabel';
import { useCallback } from 'react';
import { useParams } from 'react-router';

// ----------------------------------------------------------------------

interface RHFCheckboxProps extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  helperText?: React.ReactNode;
  controlObj: any
  conditionFlags: any;
  currentData: any;
  onChangeHandler?:any;
}

export function DAS360Checkbox({onChangeHandler, currentData, conditionFlags, controlObj, name, helperText, ...other }: RHFCheckboxProps) {
  const { control,register,getValues,setValue } = useFormContext();
  const param = useParams();
  const viewId = param?.id || 0;
  const inputProp:any = {};

  if(!currentData) {
    currentData ={logData:[]} as any;
  } else if(!currentData.logData){
    currentData.logData = [];
  }

  const checkedValue = useCallback((field: any) => {
    let data: any = false;
    if (field.value === 1) {
      data = true;
    } else if (field.value === 0) {
      data = false;
    } else {
      data = field.value;
    }
    
    // if(field.name==='Status') {
    //   data = !data;
    // }
    return data;
  }, [])


  if (!currentData) {
    currentData = { logData: [] } as any;
  } else if (!currentData.logData) {
    currentData.logData = [];
  }

  if (controlObj.required && conditionFlags?.show === "flex" && !controlObj.showOnlyInGrid) {
    register(name, {
      required: controlObj.requiredErrorMessage || `${controlObj.label} is required.`,
      // pattern: {
      //   value: /d+/,
      //   message: "This input is number only.",
      // },
      // maxLength: {
      //   value: 10,
      //   message:controlObj.minMaxErrorMessage,
      // },
    })
  }
  
  const setLogData = useCallback((field: any, newValue: any) => {
    if (newValue !== currentData[field.name]) {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field.name)
      let oldValue =JSON.parse(JSON.stringify(controlObj.subFormName))?.replace(".",'')
        oldValue = currentData[oldValue]?currentData[oldValue][controlObj.columnName] : currentData[field.name]
      
      if(onChangeHandler) {
        const onchngeDaa ={oldValue,newValue}
        onChangeHandler(onchngeDaa);
      }
      currentData.logData.push({ pageId: viewId, fieldName: field.name, label: controlObj.label, oldValue, newValue })
    } else {
      currentData.logData = currentData.logData?.filter((x: any) => x.fieldName !== field.name)
    }
  }, [])
  return (
    <Controller {...register}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <> {!controlObj.showOnlyInGrid &&  <div>
          <FormControlLabel control={<Checkbox  disabled={controlObj.isReadonly || !conditionFlags?.enable} {...field} checked={checkedValue(field)}
            onChange={(e) => {
                setValue(name, e.target.checked)
                setLogData(field, e.target.checked);
            }} />} {...other} />
          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      }</>
      )}
    />
  );
}

// ----------------------------------------------------------------------

interface RHFMultiCheckboxProps extends Omit<FormControlLabelProps, 'control' | 'label'> {
  name: string;
  options: { label: string; value: any }[];
  row?: boolean;
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
}

export function RHFMultiCheckbox({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  sx,
  ...other
}: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  const getSelected = (selectedItems: string[], item: string) =>
    selectedItems.includes(item)
      ? selectedItems.filter((value) => value !== item)
      : [...selectedItems, item];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row',
              }),
              [`& .${formControlLabelClasses.root}`]: {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
              ...sx,
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    onChange={() => field.onChange(getSelected(field.value, option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
