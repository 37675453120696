// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hook';
//
// import Header from './header';
import LoginPage from 'src/pages/auth/jwt/login';
import DealerLoginPage from 'src/pages_new/dealer-channel/login';
import HomePage from 'src/pages/home';
import Footer from './footer';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
  const pathname = usePathname();

  const isHome = pathname === '/';

  return (
   <> <HomePage/>
     <Footer/></> 
  );
}
